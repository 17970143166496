import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { firestore } from '../../helpers/Firebase';
import {
  GET_TRANSFER,
  GET_TRANSFERLIST,
  GET_PAYOUT,
  GET_PAYOUTLIST,
  GET_TRANSACTIONLIST,
} from '../actions';
import {getStripeAccID} from '../business/selectors';

import {
  getTransferSuccess,
  getTransferError,
  getTransferListSuccess,
  getTransferListError,
  getPayoutSuccess,
  getPayoutError,
  getPayoutListSuccess,
  getPayoutListError,
  getTransactionListSuccess,
  getTransactionListError,
} from './actions';

let endpointBaseUrl;
if (process.env.REACT_APP_ENV === 'development') {
  endpointBaseUrl = 'europe-west1-gladli-staging';
} else {
  endpointBaseUrl = 'europe-west1-gladli';
}

export function* watchGetTransfer() {
  yield takeEvery(GET_TRANSFER, getTransfer);
}

const getTransferAsync = async (id) => {
  const result = await firestore
    .collection('transfers')
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return { error: 'Transfer not found' };
      } else {
        return doc.data();
      }
    })
    .catch((error) => {
      return { error: error };
    });

  return result;
};

function* getTransfer({ payload }) {
  const { id } = payload;
  try {
    const transferContent = yield call(getTransferAsync, id);
    if (!transferContent.error) {
      yield put(getTransferSuccess(transferContent));
    } else {
      yield put(getTransferError(transferContent.error));
    }
  } catch (error) {
    yield put(getTransferError(error));
  }
}

// TRANSFER LIST FETCH
export function* watchGetTransferList() {
  yield takeEvery(GET_TRANSFERLIST, getTransferList);
}

const getTransferListAsync = async (stripeId) => {
  const result = await firestore
    .collection('transfers')
    .where('destination', '==', stripeId)
    .get()
    .then((snapshot) => {
      const transferList = [];
      if (snapshot.empty) {
        return { error: 'Transferlist not found' };
      } else {
        snapshot.forEach((doc) => {
          transferList.push(doc.data());
        });

        return transferList;
      }
    })
    .catch((error) => {
      console.log(error)
      return { error: error };
    });

  return result;
};

function* getTransferList() {
  const stripeId = yield select(getStripeAccID);
  try {
    if (stripeId) {
      const transferListContent = yield call(getTransferListAsync, stripeId);
      if (!transferListContent.error) {
        yield put(getTransferListSuccess(transferListContent));
      } else {
        yield put(getTransferListError(transferListContent.error));
      }
    }
  } catch (error) {
    yield put(getTransferListError(error));
  }
}


// PAYOUT FETCH
export function* watchGetPayout() {
  yield takeEvery(GET_PAYOUT, getPayout);
}

const getPayoutAsync = async (id) => {
  const result = await firestore
    .collection('payouts')
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return { error: 'Payout not found' };
      } else {
        return doc.data();
      }
    })
    .catch((error) => {
      return { error: error };
    });

  return result;
};

function* getPayout({ payload }) {
  const { id } = payload;
  try {
    const payoutContent = yield call(getPayoutAsync, id);
    if (!payoutContent.error) {
      yield put(getPayoutSuccess(payoutContent));
    } else {
      yield put(getPayoutError(payoutContent.error));
    }
  } catch (error) {
    yield put(getPayoutError(error));
  }
}

// PAYOUT LIST FETCH
export function* watchGetPayoutList() {
  yield takeEvery(GET_PAYOUTLIST, getPayoutList);
}

const getPayoutListAsync = async (stripeId) => {
  const result = await firestore
    .collection('payouts')
    .where('destination', '==', stripeId)
    .get()
    .then((snapshot) => {
      const payoutList = [];
      if (snapshot.empty) {
        return { error: 'Payoutlist not found' };
      } else {
        snapshot.forEach((doc) => {
          payoutList.push(doc.data());
        });

        return payoutList;
      }
    })
    .catch((error) => {
      console.log(error)
      return { error: error };
    });

  return result;
};

function* getPayoutList() {
  const stripeId = yield select(getStripeAccID);
  try {
    if (stripeId) {
      const payoutListContent = yield call(getPayoutListAsync, stripeId);
      if (!payoutListContent.error) {
        yield put(getPayoutListSuccess(payoutListContent));
      } else {
        yield put(getPayoutListError(payoutListContent.error));
      }
    }
  } catch (error) {
    yield put(getPayoutListError(error));
  }
}

// TRANSACTION LIST FETCH
export function* watchGetTransactionList() {
  yield takeEvery(GET_TRANSACTIONLIST, getTransactionList);
}

const getTransactionListAsync = async (bid) => {
  // Implement the logic to fetch the transaction list
  // This is just a placeholder implementation, adjust it according to your actual data source
  const result = await firestore
    .collection('transactions')
    .where('bid', '==', bid)
    .get()
    .then((snapshot) => {
      const transactionList = [];
      if (snapshot.empty) {
        return { error: 'Transaction list not found' };
      } else {
        snapshot.forEach((doc) => {
          transactionList.push(doc.data());
        });
        return transactionList;
      }
    })
    .catch((error) => {
      console.log(error)
      return { error: error };
    });

  return result;
};

function* getTransactionList() {
  const stripeId = yield select(getStripeAccID);
  try {
    if (stripeId) {
      const transactionListContent = yield call(getTransactionListAsync, stripeId);
      if (!transactionListContent.error) {
        yield put(getTransactionListSuccess(transactionListContent));
      } else {
        yield put(getTransactionListError(transactionListContent.error));
      }
    }
  } catch (error) {
    yield put(getTransactionListError(error));
  }
}


export default function* rootSaga() {
  yield all([
    fork(watchGetTransfer),
    fork(watchGetTransferList),
    fork(watchGetPayout),
    fork(watchGetPayoutList),
    fork(watchGetTransactionList),
  ]);
}