import {
  GET_TRANSFER,
  GET_TRANSFER_SUCCESS,
  GET_TRANSFER_ERROR,
  GET_TRANSFERLIST,
  GET_TRANSFERLIST_SUCCESS,
  GET_TRANSFERLIST_ERROR,
  GET_PAYOUT,
  GET_PAYOUT_SUCCESS,
  GET_PAYOUT_ERROR,
  GET_PAYOUTLIST,
  GET_PAYOUTLIST_SUCCESS,
  GET_PAYOUTLIST_ERROR,
  GET_TRANSACTIONLIST,
  GET_TRANSACTIONLIST_SUCCESS,
  GET_TRANSACTIONLIST_ERROR,
} from '../actions'

// Transfers
export const getTransfer = (id, history) => ({
  type: GET_TRANSFER,
  payload: { id, history }
})

export const getTransferSuccess = (transfer) => ({
  type: GET_TRANSFER_SUCCESS,
  payload: transfer
})

export const getTransferError = (message) => ({
  type: GET_TRANSFER_ERROR,
  payload: message
})

export const getTransferList = (id, history) => ({
  type: GET_TRANSFERLIST,
  payload: { id, history }
})

export const getTransferListSuccess = (transfer) => ({
  type: GET_TRANSFERLIST_SUCCESS,
  payload: transfer
})

export const getTransferListError = (message) => ({
  type: GET_TRANSFERLIST_ERROR,
  payload: message
})

// Payouts
export const getPayout = (id, history) => ({
  type: GET_PAYOUT,
  payload: { id, history }
})

export const getPayoutSuccess = (payout) => ({
  type: GET_PAYOUT_SUCCESS,
  payload: payout
})

export const getPayoutError = (message) => ({
  type: GET_PAYOUT_ERROR,
  payload: message
})

export const getPayoutList = (id, history) => ({
  type: GET_PAYOUTLIST,
  payload: { id, history }
})

export const getPayoutListSuccess = (payout) => ({
  type: GET_PAYOUTLIST_SUCCESS,
  payload: payout
})

export const getPayoutListError = (message) => ({
  type: GET_PAYOUTLIST_ERROR,
  payload: message
})

export const getTransactionList = (params, history) => ({
  type: GET_TRANSACTIONLIST,
  payload: { params, history }
});

export const getTransactionListSuccess = (transactions) => ({
  type: GET_TRANSACTIONLIST_SUCCESS,
  payload: transactions
});

export const getTransactionListError = (message) => ({
  type: GET_TRANSACTIONLIST_ERROR,
  payload: message
});