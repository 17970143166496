import {
  ISSUE_GIFTCARD,
  ISSUE_GIFTCARD_SUCCESS,
  ISSUE_GIFTCARD_ERROR,
  GET_GIFTCARD,
  RESET_GIFTCARD,
  GET_GIFTCARD_SUCCESS,
  GET_GIFTCARD_ERROR,
  GET_GIFTCARDLIST,
  GET_GIFTCARDLIST_SUCCESS,
  GET_GIFTCARDLIST_ERROR,
  REDEEM_GIFTCARD,
  REDEEM_GIFTCARD_SUCCESS,
  REDEEM_GIFTCARD_ERROR,
  PART_REDEEM_GIFTCARD,
  PART_REDEEM_GIFTCARD_SUCCESS,
  PART_REDEEM_GIFTCARD_ERROR
} from '../actions'

export const issueGiftcard = (giftcard, uid, history) => ({
  type: ISSUE_GIFTCARD,
  payload: { giftcard, uid, history }
})

export const issueGiftcardSuccess = (giftcard) => ({
  type: ISSUE_GIFTCARD_SUCCESS,
  payload: giftcard
})

export const issueGiftcardError = (message) => ({
  type: ISSUE_GIFTCARD_ERROR,
  payload: message
})

export const resetGiftcard = (history) => ({
  type: RESET_GIFTCARD,
  payload: { history }
})

export const getGiftcard = (id, history) => ({
  type: GET_GIFTCARD,
  payload: { id, history }
})

export const getGiftcardSuccess = (giftcard) => ({
  type: GET_GIFTCARD_SUCCESS,
  payload: giftcard
})

export const getGiftcardError = (message) => ({
  type: GET_GIFTCARD_ERROR,
  payload: message
})

export const getGiftcardList = (id, history) => ({
  type: GET_GIFTCARDLIST,
  payload: { id, history }
})

export const getGiftcardListSuccess = (giftcard) => ({
  type: GET_GIFTCARDLIST_SUCCESS,
  payload: giftcard
})

export const getGiftcardListError = (message) => ({
  type: GET_GIFTCARDLIST_ERROR,
  payload: message
})

export const redeemGiftcard = (giftcard, uid, purchaseValue, history) => ({
  type: REDEEM_GIFTCARD,
  payload: { giftcard, uid, purchaseValue, history }
})

export const redeemGiftcardSuccess = (message) => ({
  type: REDEEM_GIFTCARD_SUCCESS,
  payload: message
})

export const redeemGiftcardError = (message) => ({
  type: REDEEM_GIFTCARD_ERROR,
  payload: message
})

export const partRedeemGiftcard = (giftcard, uid, sum, history) => ({
  type: PART_REDEEM_GIFTCARD,
  payload: { giftcard, uid, sum, history }
})

export const partRedeemGiftcardSuccess = (message) => ({
  type: PART_REDEEM_GIFTCARD_SUCCESS,
  payload: message
})

export const partRedeemGiftcardError = (message) => ({
  type: PART_REDEEM_GIFTCARD_ERROR,
  payload: message
})
