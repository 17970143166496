import {
  GET_USERLIST,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_ERROR,
  GET_OFFERSLIST,
  GET_OFFERSLIST_SUCCESS,
  GET_OFFERSLIST_ERROR,
  GET_BUSINESS,
  GET_BUSINESS_SUCCESS,
  GET_BUSINESS_ERROR,
  GET_CONTENT,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_ERROR,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  UPDATE_OFFER,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_ERROR,
  ADD_OFFER,
  ADD_OFFER_SUCCESS,
  ADD_OFFER_ERROR,
  DELETE_OFFER,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_ERROR,
  RESET_CURRENT_BUSINESS
} from '../actions'

export const getBusiness = (history) => ({
  type: GET_BUSINESS,
  payload: { history }
})

export const getBusinessSuccess = (business) => ({
  type: GET_BUSINESS_SUCCESS,
  payload: business
})

export const getBusinessError = (message) => ({
  type: GET_BUSINESS_ERROR,
  payload: message
})

export const getContent = (history) => ({
  type: GET_CONTENT,
  payload: { history }
})

export const getContentSuccess = (content) => ({
  type: GET_CONTENT_SUCCESS,
  payload: content
})

export const getContentError = (message) => ({
  type: GET_CONTENT_ERROR,
  payload: message
})

export const updateBusiness = (business, history) => ({
  type: UPDATE_BUSINESS,
  payload: { business, history }
})

export const updateBusinessSuccess = (business) => ({
  type: UPDATE_BUSINESS_SUCCESS,
  payload: { ...business }
})

export const updateBusinessError = (message) => ({
  type: UPDATE_BUSINESS_ERROR,
  payload: message
})

export const getUserList = (history) => ({
  type: GET_USERLIST,
  payload: { history }
})

export const getUserListSuccess = (userList) => ({
  type: GET_USERLIST_SUCCESS,
  payload: userList
})

export const getUserListError = (message) => ({
  type: GET_USERLIST_ERROR,
  payload: message
})

export const getOffersList = (history) => ({
  type: GET_OFFERSLIST,
  payload: { history }
})

export const getOffersListSuccess = (offersList) => ({
  type: GET_OFFERSLIST_SUCCESS,
  payload: offersList
})

export const getOffersListError = (message) => ({
  type: GET_OFFERSLIST_ERROR,
  payload: message
})

export const updateUser = (user, history) => ({
  type: UPDATE_USER,
  payload: { user, history }
})

export const updateUserSuccess = (userList) => ({
  type: UPDATE_USER_SUCCESS,
  payload: userList
})

export const updateUserError = (message) => ({
  type: UPDATE_USER_ERROR,
  payload: message
})

export const addUser = (user, history) => ({
  type: ADD_USER,
  payload: { user, history }
})

export const addUserSuccess = (addedUser, list) => ({
  type: ADD_USER_SUCCESS,
  payload: { addedUser, list }
})

export const addUserError = (message) => ({
  type: ADD_USER_ERROR,
  payload: message
})

export const deleteUser = (user, history) => ({
  type: DELETE_USER,
  payload: { user, history }
})

export const deleteUserSuccess = (userList) => ({
  type: DELETE_USER_SUCCESS,
  payload: userList
})

export const deleteUserError = (message) => ({
  type: DELETE_USER_ERROR,
  payload: message
})

export const updateOffer = (id, offer, history) => ({
  type: UPDATE_OFFER,
  payload: { id, offer, history }
})

export const updateOfferSuccess = (list) => ({
  type: UPDATE_OFFER_SUCCESS,
  payload: list
})

export const updateOfferError = (message) => ({
  type: UPDATE_OFFER_ERROR,
  payload: message
})

export const addOffer = (offer, history) => ({
  type: ADD_OFFER,
  payload: { offer, history }
})

export const addOfferSuccess = (offer, list) => ({
  type: ADD_OFFER_SUCCESS,
  payload: { offer, list }
})

export const addOfferError = (message) => ({
  type: ADD_OFFER_ERROR,
  payload: message
})

export const deleteOffer = (offerId, history) => ({
  type: DELETE_OFFER,
  payload: { offerId, history }
})

export const deleteOfferSuccess = () => ({
  type: DELETE_OFFER_SUCCESS
})

export const deleteOfferError = (message) => ({
  type: DELETE_OFFER_ERROR,
  payload: message
})

export const resetCurrentBusiness = () => ({
  type: RESET_CURRENT_BUSINESS
});
