/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

/* BUSINESS */
export const GET_USERLIST = 'GET_USERLIST'
export const GET_USERLIST_SUCCESS = 'GET_USERLIST_SUCCESS'
export const GET_USERLIST_ERROR = 'GET_USERLIST_ERROR'
export const GET_OFFERSLIST = 'GET_OFFERSLIST'
export const GET_OFFERSLIST_SUCCESS = 'GET_OFFERSLIST_SUCCESS'
export const GET_OFFERSLIST_ERROR = 'GET_OFFERSLIST_ERROR'
export const GET_BUSINESS = 'GET_BUSINESS'
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS'
export const GET_BUSINESS_ERROR = 'GET_BUSINESS_ERROR'
export const GET_CONTENT = 'GET_CONTENT'
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS'
export const GET_CONTENT_ERROR = 'GET_CONTENT_ERROR'
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS'
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS'
export const UPDATE_BUSINESS_ERROR = 'UPDATE_BUSINESS_ERROR'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const ADD_USER = 'ADD_USER'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_ERROR = 'ADD_USER_ERROR'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const RESET_CURRENT_BUSINESS = 'RESET_CURRENT_BUSINESS';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_ERROR = 'UPDATE_OFFER_ERROR';
export const ADD_OFFER = 'ADD_OFFER';
export const ADD_OFFER_SUCCESS = 'ADD_OFFER_SUCCESS';
export const ADD_OFFER_ERROR = 'ADD_OFFER_ERROR';
export const DELETE_OFFER = 'DELETE_OFFER';
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_ERROR = 'DELETE_OFFER_ERROR';

/* GIFTCARDS */
export const ISSUE_GIFTCARD = 'ISSUE_GIFTCARD'
export const ISSUE_GIFTCARD_SUCCESS = 'ISSUE_GIFTCARD_SUCCESS'
export const ISSUE_GIFTCARD_ERROR = 'ISSUE_GIFTCARD_ERROR'
export const GET_GIFTCARD = 'GET_GIFTCARD'
export const RESET_GIFTCARD = 'RESET_GIFTCARD'
export const GET_GIFTCARD_SUCCESS = 'GET_GIFTCARD_SUCCESS'
export const GET_GIFTCARD_ERROR = 'GET_GIFTCARD_ERROR'
export const GET_GIFTCARDLIST = 'GET_GIFTCARDLIST'
export const GET_GIFTCARDLIST_SUCCESS = 'GET_GIFTCARDLIST_SUCCESS'
export const GET_GIFTCARDLIST_ERROR = 'GET_GIFTCARDLIST_ERROR'
export const REDEEM_GIFTCARD = 'REDEEM_GIFTCARD'
export const REDEEM_GIFTCARD_SUCCESS = 'REDEEM_GIFTCARD_SUCCESS'
export const REDEEM_GIFTCARD_ERROR = 'REDEEM_GIFTCARD_ERROR'
export const PART_REDEEM_GIFTCARD = 'PART_REDEEM_GIFTCARD'
export const PART_REDEEM_GIFTCARD_SUCCESS = 'PART_REDEEM_GIFTCARD_SUCCESS'
export const PART_REDEEM_GIFTCARD_ERROR = 'PART_REDEEM_GIFTCARD_ERROR'

/* REPORTS */
export const GET_TRANSFER = 'GET_TRANSFER'
export const GET_TRANSFER_SUCCESS = 'GET_TRANSFER_SUCCESS'
export const GET_TRANSFER_ERROR = 'GET_TRANSFER_ERROR'
export const GET_TRANSFERLIST = 'GET_TRANSFERLIST'
export const GET_TRANSFERLIST_SUCCESS = 'GET_TRANSFERLIST_SUCCESS'
export const GET_TRANSFERLIST_ERROR = 'GET_TRANSFERLIST_ERROR'
export const GET_PAYOUT = 'GET_PAYOUT'
export const GET_PAYOUT_SUCCESS = 'GET_PAYOUT_SUCCESS'
export const GET_PAYOUT_ERROR = 'GET_PAYOUT_ERROR'
export const GET_PAYOUTLIST = 'GET_PAYOUTLIST'
export const GET_PAYOUTLIST_SUCCESS = 'GET_PAYOUTLIST_SUCCESS'
export const GET_PAYOUTLIST_ERROR = 'GET_PAYOUTLIST_ERROR'
export const GET_TRANSACTIONLIST = 'GET_TRANSACTIONLIST'
export const GET_TRANSACTIONLIST_SUCCESS = 'GET_TRANSACTIONLIST_SUCCESS'
export const GET_TRANSACTIONLIST_ERROR = 'GET_TRANSACTIONLIST_ERROR'

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
// export const REGISTER_USER = 'REGISTER_USER'
// export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
// export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES'
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME'
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU'
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES'
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS'

export * from './menu/actions'
export * from './settings/actions'
export * from './auth/actions'
export * from './business/actions'
export * from './reports/actions'
export * from './giftcards/actions'
