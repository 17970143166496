// import { UserRole } from '../helpers/authHelper'

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
export const defaultLocale = 'en'
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'sv', name: 'Svenska', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' }
]

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJ_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
}

export const adminRoot = '/app'

export const themeColorStorageKey = '__theme_selected_color'
export const isMultiColorActive = false
export const defaultColor = 'dark.orangecarrot'
export const isDarkSwitchActive = true
export const defaultDirection = 'ltr'
export const themeRadiusStorageKey = '__theme_radius'
export const isAuthGuardActive = true
export const colors = [
  'orangecarrot'
]
