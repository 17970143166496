import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import { firebaseConfig } from '../constants/defaultValues'

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const firestore = firebase.firestore()
const FieldValue = firebase.firestore.FieldValue
const storage = firebase.storage()

export { auth, firestore, storage, FieldValue }
