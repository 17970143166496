import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { firestore } from '../../helpers/Firebase';
import axios from 'axios';
import {
  GET_USERLIST,
  GET_OFFERSLIST,
  GET_BUSINESS,
  GET_CONTENT,
  UPDATE_BUSINESS,
  UPDATE_USER,
  UPDATE_OFFER,
  ADD_OFFER,
  DELETE_OFFER,
  ADD_USER,
  DELETE_USER,
} from '../actions';

import {
  getUserListSuccess,
  getUserListError,
  getOffersListSuccess,
  getOffersListError,
  getBusinessSuccess,
  getBusinessError,
  getContentSuccess,
  getContentError,
  updateBusinessSuccess,
  updateBusinessError,
  updateUserSuccess,
  updateUserError,
  addUserSuccess,
  addUserError,
  deleteUserSuccess,
  deleteUserError,
  updateOfferSuccess,
  updateOfferError,
  addOfferSuccess,
  addOfferError,
  deleteOfferSuccess,
  deleteOfferError,
} from './actions';

import { setCurrentBusiness } from '../../helpers/Utils';

import { getCurrentUser } from '../../helpers/Utils';
import { makeId } from '../../helpers/Utils';

let endpointBaseUrl;
if (process.env.REACT_APP_ENV === 'development') {
  endpointBaseUrl = 'europe-west1-gladli-staging';
} else {
  endpointBaseUrl = 'europe-west1-gladli';
}

export function* watchGetBusiness() {
  yield takeEvery(GET_BUSINESS, getBusiness);
}

export const getBusinessAsync = async () => {
  const result = await firestore
    .collection('businesses')
    .doc(getCurrentUser().bid)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return { error: 'Business not found' };
      } else {
        return doc.data();
      }
    })
    .catch((error) => {
      return { error: error };
    });

  return result;
};

function* getBusiness() {
  try {
    const businessContent = yield call(getBusinessAsync);
    if (!businessContent.error) {
      const {
        about,
        bid,
        cat,
        currency,
        country,
        category,
        city,
        email,
        hours,
        image,
        logo,
        online,
        phone,
        rating,
        stripe,
        title,
        values,
        multisite,
        locations,
      } = businessContent;
      yield put(
        getBusinessSuccess({
          about,
          bid,
          cat,
          currency,
          category,
          country,
          city,
          email,
          hours,
          image,
          locations,
          logo,
          multisite,
          online,
          phone,
          rating,
          stripe,
          title,
          values,
        })
      );
    } else {
      yield put(getBusinessError(businessContent.error));
    }
  } catch (error) {
    yield put(getBusinessError(error));
  }
}

export function* watchGetContent() {
  yield takeEvery(GET_CONTENT, getContent);
}

export const getContentAsync = async (id) => {
  const result = await firestore
    .collection('content')
    .doc('common')
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return { error: 'Content not found' };
      } else {
        return doc.data();
      }
    })
    .catch((error) => {
      return { error: error };
    });

  return result;
};

function* getContent() {
  try {
    const businessContent = yield call(getContentAsync);
    if (!businessContent.error) {
      yield put(getContentSuccess({ ...businessContent }));
    } else {
      yield put(getContentError(businessContent.error));
    }
  } catch (error) {
    yield put(getContentError(error));
  }
}

export function* watchUpdateBusiness() {
  yield takeEvery(UPDATE_BUSINESS, updateBusiness);
}

const updateBusinessAsync = async (payload) => {
  const { business } = payload;
  try {
    await firestore
      .collection('businesses')
      .doc(getCurrentUser().bid)
      .set(business, { merge: true });
    return business;
  } catch (error) {
    return { error: error };
  }
};

function* updateBusiness({ payload }) {
  try {
    const result = yield call(updateBusinessAsync, payload);

    if (!result.error) {
      yield put(updateBusinessSuccess(result));
      setCurrentBusiness(payload.business)
    } else {
      yield put(updateBusinessError(result.error));
    }
  } catch (error) {
    yield put(updateBusinessError(error));
  }
}

// USER LIST FETCH
export function* watchGetUserList() {
  yield takeEvery(GET_USERLIST, getUserList);
}

const getUserListAsync = async () => {
  const result = await firestore
    .collection('business_users')
    .where('bid', '==', getCurrentUser().bid)
    .get()
    .then((snapshot) => {
      const userList = [];
      if (snapshot.empty) {
        return { error: 'User list not found' };
      } else {
        snapshot.forEach((doc) => {
          userList.push(doc.data());
        });

        return userList;
      }
    })
    .catch((error) => {
      return { error: error };
    });

  return result;
};

function* getUserList() {
  try {
    const userListContent = yield call(getUserListAsync);
    if (!userListContent.error) {
      yield put(getUserListSuccess(userListContent));
    } else {
      yield put(getUserListError(userListContent.error));
    }
  } catch (error) {
    yield put(getUserListError(error));
  }
}

// OFFERS LIST FETCH
export function* watchGetOffersList() {
  yield takeEvery(GET_OFFERSLIST, getOffersList);
}

const getOffersListAsync = async () => {
  const result = await firestore
    .collection('business_offers')
    .where('bid', '==', getCurrentUser().bid)
    .get()
    .then((snapshot) => {
      const offersList = [];
      if (snapshot.empty) {
        return { error: 'Offers list not found' };
      } else {
        snapshot.forEach((doc) => {
          offersList.push(doc.data());
        });

        return offersList;
      }
    })
    .catch((error) => {
      return { error: error };
    });

  return result;
};

function* getOffersList() {
  try {
    const offersListContent = yield call(getOffersListAsync);
    if (!offersListContent.error) {
      yield put(getOffersListSuccess(offersListContent));
    } else {
      yield put(getOffersListError(offersListContent.error));
    }
  } catch (error) {
    yield put(getOffersListError(error));
  }
}

// UPDATE BUSINESS USER
export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

const updateUserAsync = async (payload) => {
  const { user } = payload;
  try {
    await firestore.collection('business_users').doc(user.uid).update(user);
    return;
  } catch (error) {
    return { error: error };
  }
};

function* updateUser({ payload }) {
  try {
    const result = yield call(updateUserAsync, payload);
    yield call(getUserListAsync);

    if (!result.error) {
      yield put(updateUserSuccess(result));
    } else {
      yield put(updateUserError(result.error));
    }
  } catch (error) {
    yield put(updateUserError(error));
  }
}

// ADD BUSINESS USER
export function* watchAddUser() {
  yield takeLatest(ADD_USER, addUser);
}

const addUserAsync = async (payload) => {
  const response = await axios.post(
    `https://${endpointBaseUrl}.cloudfunctions.net/addBusinessUser`,
    payload.user
  );

  if (response.data.code === 'auth/email-already-exists') {
    return { error: response.data.code };
  } else if (response.data.code) {
    return { error: 'error' };
  } else {
    return true;
  }
};

function* addUser({ payload }) {
  try {
    const addUserStatus = yield call(addUserAsync, payload);

    if (!addUserStatus.error) {
      const result = yield call(getUserListAsync);
      yield put(addUserSuccess('success', result));
    } else {
      yield put(addUserError(addUserStatus));
    }
  } catch (error) {
    yield put(addUserError(error));
  }
}

// DELETE BUSINESS USER
export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

const deleteUserAsync = async (payload) => {
  const { user } = payload;

  try {
    await axios.post(
      `https://${endpointBaseUrl}.cloudfunctions.net/deleteBusinessUser`,
      user
    );

    return;
  } catch (error) {
    return { error: error };
  }
};

function* deleteUser({ payload }) {
  try {
    yield call(deleteUserAsync, payload);
    const result = yield call(getUserListAsync);

    if (!result.error) {
      yield put(deleteUserSuccess(result));
    } else {
      yield put(deleteUserError(result.error));
    }
  } catch (error) {
    yield put(deleteUserError(error));
  }
}

// UPDATE BUSINESS OFFER
export function* watchUpdateOffer() {
  yield takeEvery(UPDATE_OFFER, updateOffer);
}

const updateOfferAsync = async (payload) => {
  const { id, offer } = payload;
  try {
    await firestore.collection('business_offers').doc(id).update(offer);
    return { success: true };
  } catch (error) {
    return { error: error };
  }
};

function* updateOffer({ payload }) {
  try {
    const result = yield call(updateOfferAsync, payload);
    
    if (!result.error) {
      const result = yield call(getOffersListAsync);
      yield put(updateOfferSuccess(result));
    } else {
      yield put(updateOfferError(result.error));
    }
  } catch (error) {
    yield put(updateOfferError(error));
  }
}

// ADD BUSINESS OFFER
export function* watchAddOffer() {
  yield takeLatest(ADD_OFFER, addOffer);
}

const addOfferAsync = async (payload) => {
  const { offer } = payload;
  try {
    const offerId = await makeId(25)

    await firestore
      .collection('business_offers')
      .doc(offerId)
      .set({...offer, id: offerId}, {merge: true});
    return {...offer, id: offerId};
  } catch (error) {
    return { error: error };
  }
};

function* addOffer({ payload }) {
  try {
    const addOfferStatus = yield call(addOfferAsync, payload);

    if (!addOfferStatus.error) {
      const result = yield call(getOffersListAsync);
      yield put(addOfferSuccess(addOfferStatus, result)); // Assuming addOfferStatus is the newly added offer
    } else {
      yield put(addOfferError(addOfferStatus));
    }
  } catch (error) {
    yield put(addOfferError(error));
  }
}

// DELETE BUSINESS OFFER
export function* watchDeleteOffer() {
  yield takeEvery(DELETE_OFFER, deleteOffer);
}

const deleteOfferAsync = async (payload) => {
  const { offerId } = payload;
  try {
    await firestore.collection('business_offers').doc(offerId).delete();
    return { success: true };
  } catch (error) {
    return { error: error };
  }
};

function* deleteOffer({ payload }) {
  try {
    const result = yield call(deleteOfferAsync, payload);

    if (!result.error) {
      yield put(deleteOfferSuccess());
    } else {
      yield put(deleteOfferError(result.error));
    }
  } catch (error) {
    yield put(deleteOfferError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUserList),
    fork(watchGetOffersList),
    fork(watchGetBusiness),
    fork(watchGetContent),
    fork(watchUpdateBusiness),
    fork(watchUpdateUser),
    fork(watchAddUser),
    fork(watchDeleteUser),
    fork(watchAddOffer),
    fork(watchDeleteOffer),
    fork(watchUpdateOffer),
  ]);
}
