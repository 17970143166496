import { combineReducers } from 'redux'
import settings from './settings/reducer'
import menu from './menu/reducer'
import authUser from './auth/reducer'
import business from './business/reducer'
import reports from './reports/reducer'
import giftcards from './giftcards/reducer'

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  business,
  reports,
  giftcards
})

export default reducers
