import {
  GET_USERLIST,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_ERROR,
  GET_OFFERSLIST,
  GET_OFFERSLIST_SUCCESS,
  GET_OFFERSLIST_ERROR,
  GET_BUSINESS,
  GET_BUSINESS_SUCCESS,
  GET_BUSINESS_ERROR,
  GET_CONTENT,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_ERROR,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  UPDATE_OFFER,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_ERROR,
  ADD_OFFER,
  ADD_OFFER_SUCCESS,
  ADD_OFFER_ERROR,
  DELETE_OFFER,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_ERROR,
  RESET_CURRENT_BUSINESS
} from '../actions'
import { getCurrentBusiness } from '../../helpers/Utils'

const INIT_STATE = {
  loading: false,
  error: '',
  userList: null,
  offersList: null,
  currBusiness: getCurrentBusiness(),
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERLIST:
      return { ...state, loading: true, error: '' }
    case GET_USERLIST_SUCCESS:
      return { ...state, loading: false, userList: action.payload, error: '' }
    case GET_USERLIST_ERROR:
      return { ...state, loading: false, userList: null, error: action.payload }
    case GET_OFFERSLIST:
      return { ...state, loading: true, error: '' }
    case GET_OFFERSLIST_SUCCESS:
      return { ...state, loading: false, offersList: action.payload, error: '' }
    case GET_OFFERSLIST_ERROR:
      return { ...state, loading: false, offersList: null, error: action.payload }
    case GET_BUSINESS:
      return { ...state, loading: true, error: '' }
    case GET_BUSINESS_SUCCESS:
      return { ...state, loading: false, currBusiness: action.payload, error: '' }
    case GET_BUSINESS_ERROR:
      return { ...state, loading: false, currBusiness: null, error: action.payload }
    case GET_CONTENT:
      return { ...state, loading: true, error: '' }
    case GET_CONTENT_SUCCESS:
      return { ...state, loading: false, content: action.payload, error: '' }
    case GET_CONTENT_ERROR:
      return { ...state, loading: false, content: null, error: action.payload }
    case UPDATE_BUSINESS:
      return { ...state, loading: true, error: '' }
    case UPDATE_BUSINESS_SUCCESS:
      return { ...state, loading: false, currBusiness: action.payload, error: '' }
    case UPDATE_BUSINESS_ERROR:
      return { ...state, loading: false, error: action.payload }
    case UPDATE_USER:
      return { ...state, loading: true, error: '' }
    case UPDATE_USER_SUCCESS:
      return { ...state, loading: false, userList: action.payload, error: '' }
    case UPDATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload }
    case ADD_USER:
      return { ...state, loading: true, addedUser: '', error: '' }
    case ADD_USER_SUCCESS:
      return { ...state, loading: false, userList: action.payload.list, addedUser: action.payload.addedUser, error: '' }
    case ADD_USER_ERROR:
      return { ...state, loading: false, error: action.payload }
    case DELETE_USER:
      return { ...state, loading: true, error: '' }
    case DELETE_USER_SUCCESS:
      return { ...state, loading: false, userList: action.payload, error: '' }
    case DELETE_USER_ERROR:
      return { ...state, loading: false, error: action.payload }
    case UPDATE_OFFER:
      return { ...state, loading: true, error: '' }
    case UPDATE_OFFER_SUCCESS:
      return { ...state, loading: false, offersList: action.payload, error: '' }
    case UPDATE_OFFER_ERROR:
      return { ...state, loading: false, error: action.payload }
    case ADD_OFFER:
      return { ...state, loading: true, error: '' }
    case ADD_OFFER_SUCCESS:
      return { ...state, loading: false, offersList: action.payload.list, error: '' }
    case ADD_OFFER_ERROR:
      return { ...state, loading: false, error: action.payload }
    case DELETE_OFFER:
      return { ...state, loading: true, error: '' }
    case DELETE_OFFER_SUCCESS:
      return { ...state, loading: false, error: ''}
    case DELETE_OFFER_ERROR:
      return { ...state, loading: false, error: action.payload }
    case RESET_CURRENT_BUSINESS:
      return {
        ...state,
        userList: null,
        currBusiness: null,
        offersList: null,
        error: ''
      };

    default:
      return { ...state }
  }
}
