import {
  GET_TRANSFER,
  GET_TRANSFER_SUCCESS,
  GET_TRANSFER_ERROR,
  GET_TRANSFERLIST,
  GET_TRANSFERLIST_SUCCESS,
  GET_TRANSFERLIST_ERROR,
  GET_PAYOUT,
  GET_PAYOUT_SUCCESS,
  GET_PAYOUT_ERROR,
  GET_PAYOUTLIST,
  GET_PAYOUTLIST_SUCCESS,
  GET_PAYOUTLIST_ERROR,
  GET_TRANSACTIONLIST,
  GET_TRANSACTIONLIST_SUCCESS,
  GET_TRANSACTIONLIST_ERROR,
} from '../actions';

const INIT_STATE = {
  loading: false,
  error: '',
  transfer: '',
  transferList: [],
  transactionList: [],
  payout: '',
  payoutList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSFER:
      return { ...state, loading: true, error: '' };
    case GET_TRANSFER_SUCCESS:
      return { ...state, loading: false, transfer: action.payload, error: '' };
    case GET_TRANSFER_ERROR:
      return {
        ...state,
        loading: false,
        transfer: null,
        error: action.payload,
      };
    case GET_TRANSFERLIST:
      return { ...state, loading: true, error: '' };
    case GET_TRANSFERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        transferList: action.payload,
        error: '',
      };
    case GET_TRANSFERLIST_ERROR:
      return {
        ...state,
        loading: false,
        transferList: null,
        error: action.payload,
      };

    case GET_PAYOUT:
      return { ...state, loading: true, error: '' };
    case GET_PAYOUT_SUCCESS:
      return { ...state, loading: false, payout: action.payload, error: '' };
    case GET_PAYOUT_ERROR:
      return {
        ...state,
        loading: false,
        payout: null,
        error: action.payload,
      };
    case GET_PAYOUTLIST:
      return { ...state, loading: true, error: '' };
    case GET_PAYOUTLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        payoutList: action.payload,
        error: '',
      };
    case GET_PAYOUTLIST_ERROR:
      return {
        ...state,
        loading: false,
        payoutList: null,
        error: action.payload,
      };

    case GET_TRANSACTIONLIST:
      return { ...state, loading: true, error: '' };
    case GET_TRANSACTIONLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionList: action.payload,
        error: '',
      };
    case GET_TRANSACTIONLIST_ERROR:
      return {
        ...state,
        loading: false,
        transactionList: null,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};
