/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects'
import authSagas from './auth/saga'
import businessSagas from './business/saga'
import giftcardSagas from './giftcards/saga'
import reportsSagas from './reports/saga'

export default function * rootSaga (getState) {
  yield all([authSagas(), businessSagas(), giftcardSagas(), reportsSagas()])
}
