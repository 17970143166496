import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey
} from '../constants/defaultValues'

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key]
    const B = b[key]
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1
    }
    return -1
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return `${dd}.${mm}.${yyyy}`
}

export const getCurrentTime = () => {
  const now = new Date()
  return `${now.getHours()}:${now.getMinutes()}`
}

export const getDirection = () => {
  let direction = defaultDirection

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction')
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error)
    direction = defaultDirection
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  }
}
export const setDirection = (localValue) => {
  let direction = 'ltr'
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue
  }
  try {
    localStorage.setItem('direction', direction)
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error)
  }
}

export const getCurrentColor = () => {
  let currentColor = defaultColor
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey)
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error)
    currentColor = defaultColor
  }
  return currentColor
}

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color)
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error)
  }
}

export const getCurrentRadius = () => {
  let currentRadius = 'rounded'
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey)
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentRadius -> error', error)
    currentRadius = 'rounded'
  }
  return currentRadius
}
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius)
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentRadius -> error', error)
  }
}

export const getCurrentLanguage = () => {
  let language = defaultLocale
  try {
    language = localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentLanguage -> error', error)
    language = defaultLocale
  }
  return language
}
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale)
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentLanguage -> error', error)
  }
}

export const getCurrentUser = () => {
  let user = null
  try {
    user = localStorage.getItem('gladli_current_user') != null ? JSON.parse(localStorage.getItem('gladli_current_user')) : null
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error)
    user = null
  }
  return user
}

export const getCurrentBusiness = () => {
  let business = null
  try {
    business = localStorage.getItem('gladli_current_business') != null ? JSON.parse(localStorage.getItem('gladli_current_business')) : null
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentBusiness -> error', error)
    business = null
  }
  return business
}

export const getCurrentContent = () => {
  let content = null
  try {
    content = localStorage.getItem('gladli_current_content') != null ? JSON.parse(localStorage.getItem('gladli_current_content')) : null
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentContent -> error', error)
    content = null
  }
  return content
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gladli_current_user', JSON.stringify(user))
    } else {
      localStorage.removeItem('gladli_current_user')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error)
  }
}

export const setCurrentBusiness = (business) => {
  try {
    if (business) {
      localStorage.setItem('gladli_current_business', JSON.stringify(business))
    } else {
      localStorage.removeItem('gladli_current_business')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentBusiness -> error', error)
  }
}

export const setCurrentContent = (content) => {
  try {
    if (content) {
      localStorage.setItem('gladli_current_content', JSON.stringify(content))
    } else {
      localStorage.removeItem('gladli_current_content')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentBusiness -> error', error)
  }
}

export const getCurrentGiftcard = () => {
  let giftcard = null
  try {
    giftcard = localStorage.getItem('gladli_current_giftcard') != null ? JSON.parse(localStorage.getItem('gladli_current_giftcard')) : null
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentGiftcard -> error', error)
    giftcard = null
  }
  return giftcard
}

export const setCurrentGiftcard = (giftcard) => {
  try {
    if (giftcard) {
      localStorage.setItem('gladli_current_giftcard', JSON.stringify(giftcard))
    } else {
      localStorage.removeItem('gladli_current_giftcard')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentGiftcard -> error', error)
  }
}

export const formatCurrency = (amount, currency) => {
  let formattedAmount;

  // Convert the amount to the main unit (e.g., from öre to SEK or from cents to EUR)
  const mainUnitAmount = amount / 100;

  if (currency === 'sek') {
    // For SEK, use space as thousand separator and comma for decimal
    formattedAmount = mainUnitAmount.toLocaleString('sv-SE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (currency === 'eur') {
    // For EUR, use comma as thousand separator and period for decimal
    formattedAmount = mainUnitAmount.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    // Default formatting (can be adjusted for other currencies)
    formattedAmount = mainUnitAmount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return formattedAmount;
};

export const roundOffSmallestUnit = (amount) => {
  // Divide by 100 to convert to main unit and then round
  const roundedAmount = Math.round(amount / 100);

  return roundedAmount;
};

export const makeId = async (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const toDateTime = (secs) => {
  var t = new Date(1970, 0, 1);
  t.setSeconds(secs);
  console.log(t)
  if (t === 'Invalid Date') {
    return undefined
  }
  return t;
}