import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { firestore, FieldValue } from '../../helpers/Firebase';
import axios from 'axios';
import {
  ISSUE_GIFTCARD,
  GET_GIFTCARD,
  GET_GIFTCARDLIST,
  REDEEM_GIFTCARD,
  PART_REDEEM_GIFTCARD,
} from '../actions';

import {
  issueGiftcardSuccess,
  issueGiftcardError,
  getGiftcardSuccess,
  getGiftcardError,
  getGiftcardListSuccess,
  getGiftcardListError,
  redeemGiftcardSuccess,
  redeemGiftcardError,
  partRedeemGiftcardSuccess,
  partRedeemGiftcardError,
} from './actions';

import { getCurrentUser } from '../../helpers/Utils';

let endpointBaseUrl;
if (process.env.REACT_APP_ENV === 'development') {
  endpointBaseUrl = 'europe-west1-gladli-staging';
} else {
  endpointBaseUrl = 'europe-west1-gladli';
}

// ISSUE GIFT CARD
export function* watchIssueGiftcard() {
  yield takeEvery(ISSUE_GIFTCARD, issueGiftcard);
}

const issueGiftcardAsync = async (payload) => {
  const { giftcard } = payload;

  try {
    await axios.post(
      `https://${endpointBaseUrl}.cloudfunctions.net/issueDashGiftcard`,
      giftcard
    );

    return;
  } catch (error) {
    return { error: error };
  }
};

function* issueGiftcard({ payload }) {
  try {
    yield call(issueGiftcardAsync, payload);
    const result = yield call(getGiftcardListAsync);

    if (!result.error) {
      yield put(issueGiftcardSuccess(result));
    } else {
      yield put(issueGiftcardError(result.error));
    }
  } catch (error) {
    yield put(issueGiftcardError(error));
  }
}

export function* watchGetGiftcard() {
  yield takeEvery(GET_GIFTCARD, getGiftcard);
}

const getGiftcardAsync = async (id) => {
  const result = await firestore
    .collection('giftcards')
    .where('bid', '==', getCurrentUser().bid)
    .where('simple_id', '==', id)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return { error: 'Giftcard not found' };
      } else {
        return snapshot.docs[0].data();
      }
    })
    .catch((error) => {
      return { error: error };
    });

  return result;
};

function* getGiftcard({ payload }) {
  const { id } = payload;
  try {
    const giftcardContent = yield call(getGiftcardAsync, id);
    if (!giftcardContent.error) {
      const {
        amount,
        amount_redeemed,
        bid,
        expires,
        id,
        initial_amount,
        purchaseAmount,
        owner,
        redeemed,
        simple_id,
      } = giftcardContent;
      yield put(
        getGiftcardSuccess({
          amount,
          amount_redeemed,
          bid,
          expires,
          id,
          initial_amount,
          purchaseAmount,
          owner,
          redeemed,
          simple_id,
        })
      );
    } else {
      yield put(getGiftcardError(giftcardContent.error));
    }
  } catch (error) {
    yield put(getGiftcardError(error));
  }
}

// GIFTCARD LIST FETCH
export function* watchGetGiftcardList() {
  yield takeEvery(GET_GIFTCARDLIST, getGiftcardList);
}

const getGiftcardListAsync = async () => {
  const result = await firestore
    .collection('giftcards')
    .where('bid', '==', getCurrentUser().bid)
    .get()
    .then((snapshot) => {
      const giftcardList = [];
      if (snapshot.empty) {
        return { error: 'Giftcardlist not found' };
      } else {
        snapshot.forEach((doc) => {
          giftcardList.push(doc.data());
        });

        return giftcardList;
      }
    })
    .catch((error) => {
      return { error: error };
    });

  return result;
};

function* getGiftcardList() {
  try {
    const giftcardListContent = yield call(getGiftcardListAsync);
    if (!giftcardListContent.error) {
      yield put(getGiftcardListSuccess(giftcardListContent));
    } else {
      yield put(getGiftcardListError(giftcardListContent.error));
    }
  } catch (error) {
    yield put(getGiftcardListError(error));
  }
}

// PARTIAL REDEEM GIFT CARD
export function* watchPartRedeemGiftcard() {
  yield takeEvery(PART_REDEEM_GIFTCARD, partRedeemGiftcard);
}

const partRedeemGiftcardAsync = async (giftcard, uid, sum) => {
  const { amount, amount_redeemed, expires, id, purchaseAmount } = giftcard;
  const now = new Date().getTime();
  let result = {};

  if (now > expires) {
    return { error: 'Gift card expired!' };
  }

  result = await firestore
    .collection('giftcards')
    .doc(id)
    .set(
      {
        amount: amount - parseInt(sum, 10),
        amount_redeemed: amount_redeemed + parseInt(sum, 10),
        purchaseAmount: parseInt(sum, 10) + parseInt(purchaseAmount, 10),
        redeemed_by: FieldValue.arrayUnion({
          uid: uid,
          amount: parseInt(sum, 10),
          date: Date.now(),
        }),
      },
      { merge: true }
    )
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      console.log('Error:', error);
      return { error: error };
    });

  return result;
};

function* partRedeemGiftcard({ payload }) {
  const { giftcard, uid, sum } = payload;

  try {
    const result = yield call(partRedeemGiftcardAsync, giftcard, uid, sum);

    if (!result.error) {
      yield put(partRedeemGiftcardSuccess(result));
    } else {
      yield put(partRedeemGiftcardError(result.error));
    }
  } catch (error) {
    yield put(partRedeemGiftcardError(error));
  }
}

// FULL REDEEM GIFT CARD
export function* watchRedeemGiftcard() {
  yield takeEvery(REDEEM_GIFTCARD, redeemGiftcard);
}

const redeemGiftcardAsync = async (giftcard, uid, purchaseAmount) => {
  const { expires, id, amount, initial_amount } = giftcard;
  const currPurchaseAmount = giftcard.purchaseAmount || 0;
  console.log(currPurchaseAmount, purchaseAmount)
  const now = new Date().getTime();
  let result = {};

  if (now > expires) {
    return { error: 'Gift card expired!' };
  }

  result = await firestore
    .collection('giftcards')
    .doc(id)
    .set(
      {
        amount: 0,
        amount_redeemed: parseInt(initial_amount, 10),
        purchaseAmount: parseInt(currPurchaseAmount, 10) + parseInt(purchaseAmount, 10),
        redeemed: true,
        redeemed_by: FieldValue.arrayUnion({
          uid: uid,
          amount: parseInt(amount, 10),
          date: Date.now(),
        }),
      },
      { merge: true }
    )
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      console.log('Error:', error);
      return { error: error };
    });

  return result;
};

function* redeemGiftcard({ payload }) {
  const { giftcard, uid, purchaseAmount } = payload;

  try {
    const result = yield call(redeemGiftcardAsync, giftcard, uid, parseInt(purchaseAmount, 10));

    if (!result.error) {
      yield put(redeemGiftcardSuccess(result));
    } else {
      yield put(redeemGiftcardError(result.error));
    }
  } catch (error) {
    yield put(redeemGiftcardError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchIssueGiftcard),
    fork(watchGetGiftcard),
    fork(watchGetGiftcardList),
    fork(watchRedeemGiftcard),
    fork(watchPartRedeemGiftcard),
  ]);
}
