import {
  ISSUE_GIFTCARD,
  ISSUE_GIFTCARD_SUCCESS,
  ISSUE_GIFTCARD_ERROR,
  GET_GIFTCARD,
  RESET_GIFTCARD,
  GET_GIFTCARD_SUCCESS,
  GET_GIFTCARD_ERROR,
  GET_GIFTCARDLIST,
  GET_GIFTCARDLIST_SUCCESS,
  GET_GIFTCARDLIST_ERROR,
  REDEEM_GIFTCARD,
  REDEEM_GIFTCARD_SUCCESS,
  REDEEM_GIFTCARD_ERROR,
  PART_REDEEM_GIFTCARD,
  PART_REDEEM_GIFTCARD_SUCCESS,
  PART_REDEEM_GIFTCARD_ERROR,
} from '../actions';

const INIT_STATE = {
  loading: false,
  error: '',
  giftcard: '',
  userList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ISSUE_GIFTCARD:
      return { ...state, loading: true, error: '' };
    case ISSUE_GIFTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardList: action.payload,
        error: '',
      };
    case ISSUE_GIFTCARD_ERROR:
      return {
        ...state,
        loading: false,
        giftcardList: null,
        error: action.payload,
      };
    case GET_GIFTCARD:
      return { ...state, loading: true, error: '' };
    case RESET_GIFTCARD:
      return { 
        ...state,
        loading: false,
        giftcard: null,
        error: '' };
    case GET_GIFTCARD_SUCCESS:
      return { ...state, loading: false, giftcard: action.payload, error: '' };
    case GET_GIFTCARD_ERROR:
      return {
        ...state,
        loading: false,
        giftcard: null,
        error: action.payload,
      };
    case GET_GIFTCARDLIST:
      return { ...state, loading: true, error: '' };
    case GET_GIFTCARDLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardList: action.payload,
        error: '',
      };
    case GET_GIFTCARDLIST_ERROR:
      return {
        ...state,
        loading: false,
        giftcardList: null,
        error: action.payload,
      };
    case REDEEM_GIFTCARD:
      return { ...state, loading: true, error: '' };
    case REDEEM_GIFTCARD_SUCCESS:
      return { ...state, loading: false, error: '' };
    case REDEEM_GIFTCARD_ERROR:
      return { ...state, loading: false, error: action.payload };
    case PART_REDEEM_GIFTCARD:
      return { ...state, loading: true, error: '' };
    case PART_REDEEM_GIFTCARD_SUCCESS:
      return { ...state, loading: false, error: '' };
    case PART_REDEEM_GIFTCARD_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
